import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#152a3d',
        secondary: '#235d82',
        accent: '#c7b583',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
        // albastru inchis: 152a3d
        // albastru mediu: 235d82
        // albastru deschis: 388fc5
        // galben: c7b583
      },
    },
  },
});