import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/scss/main.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import VueScrollactive from 'vue-scrollactive';
import VueTypedJs from "vue-typed-js";

Vue.config.productionTip = false

Vue.use(VueTypedJs);
Vue.use(VueScrollactive);
Vue.use(require('vue-moment'));

import { register } from 'timeago.js'

const localeFunc = (number, index, totalSec) => {
  return [
    ["chiar acum", "chiar acum"],
    ["acum %s secunde", "peste %s secunde"],
    ["acum un minut", "peste un minut"],
    ["acum %s minute", "peste %s minute"],
    ["acum o oră", "peste o oră"],
    ["acum %s ore", "peste %s ore"],
    ["acum o zi", "peste o zi"],
    ["acum %s zile", "peste %s zile"],
    ["acum o săptămână", "peste o săptămână"],
    ["acum %s săptămâni", "peste %s săptămâni"],
    ["acum o lună", "peste o lună"],
    ["acum %s luni", "peste %s luni"],
    ["acum un an", "peste un an"],
    ["acum %s ani", "peste %s ani"],
  ][index];
};
register("ro", localeFunc);
 
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
