import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import authentication from '../store-modules/authentication'
import app from '../store-modules/app'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage
    })
  ],
  modules: {
    authentication,
    app
  }
})
