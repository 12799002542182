const state = {
    isAuthenticated: false,
    auth_token: '',
    user: null
}

const getters = {
    isAuthenticated: (state) => { 
        return state.isAuthenticated 
    },
    auth_token: (state) => { 
        return state.auth_token 
    },
    user: (state) => {
        return state.user
    }
}

const mutations = {
    logOff(state) {
        state.isAuthenticated = false;
        state.auth_token = '';
        state.user = null;
    },
    setAuthenticated(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
    },
    setAuthToken(state, auth_token) {
        state.auth_token = auth_token;
    },
    setUser(state, user) {
        state.user = user;
    }
}

const actions = {
    authenticate({ commit }, data) {
        new Promise(() => {
            commit('setAuthenticated', true);
            commit('setAuthToken', data.token);
            commit('setUser', {
                id: data.id,
                firstName: data.firstName,
                lastName: data.lastName,
                avatarUrl: data.avatarUrl,
                gender: data.gender,
                isAdmin: data.isAdmin
            })
        });
    },
    logOff({ commit }) {
        return new Promise((resolve) => {
            resolve(commit('logOff'));
        })
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}