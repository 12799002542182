import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Landing',
    path: '/',
    component: () => import('../views/Landing.vue')
  },
  {
    name: 'Login',
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    component: () => import('../views/Main.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        name: 'Results',
        path: '/results',
        component: () => import('../views/Results.vue')
      },
      {
        name: 'Notes',
        path: '/notes',
        component: () => import('../views/Notes.vue')
      },
      {
        name: 'Profile',
        path: '/profile',
        component: () => import('../views/Profile.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters["authentication/isAuthenticated"]) {
      next({
        path: '/login'
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
